<template>
 <div class="report" v-if="report">
  <div class="group">
    <div class="info">
      <label>Total Wallet: </label>
      <span>{{report && report.totalWallet}}</span>
    </div>
      <div class="info">
      <label>Total Consumer: </label>
      <span>{{report && report.totalConsumer}}</span>
    </div>
    <div class="info">
      <label>Total Spending: </label>
      <span>{{report && report.totalSpending}}</span>
    </div>
  </div>
  <template v-for="(value, key, index) in report.reportByMap">
    <div class="report-map" :key="index">
    <div class="map-name"> {{`Planet ${index + 1}` }}</div>
    <div class="info">
      <label>Consumer: </label>
      <span>{{value && value.consumer}}</span>
    </div>
    <div class="info">
      <label>Spending: </label>
      <span>{{value && value.spending}}</span>
    </div>
    <div class="info">
      <label>Morphing: </label>
      <span>{{value && value.morphing}}</span>
    </div>
  </div>
  </template>

 </div>
 <div v-else class="progress">
 <a-progress :percent="percentLoading" status="active" />
 </div>
</template>
<script>
export default {
  name: 'TokenReport',
  created() {
    this.setBreadcrumb([{
      path: '/token-report',
      label: 'Token Ingame Report'
    }])
    this.initContract()
  },
  destroyed() {
    this.$store.commit('setReport', null)
  },
  computed: {
    report() {
      return this.$store.getters.report
    },
    percentLoading() {
      return this.$store.getters.percentLoading
    }
  },
  methods: {
   async initContract() {
    await this.$store.dispatch('setWeb3')
    await this.$store.dispatch('initContarct')
    this.$store.dispatch('getReport')
    }
  }
}
</script>
<style lang="scss" scoped>
  .report {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    .group, .report-map {
      padding: 10px;
      border-bottom: 1px dashed gray;
    }
    .info {
      display: flex;
    }
    .map-name {
      font-size: 24px;
    }
    label {
      min-width: 200px;
      font-size: 20px;
    }
    span {
      font-weight: bold;
      font-size: 20px;
      width: 100%;
      text-align: right;
    }
  }
  .progress {
    padding: 50px 20px;
    max-width: 600px;
    margin: 0 auto;
  }

</style>
